import { TableRow, TableCell, Chip, Box } from '@mui/material';
import ItemKey, { ItemKeyMobile } from './ItemKey';
import type { DataRowProps } from '../types';

const createLabelStyles = (isNested: boolean) => ({
  width: '0%',
  padding: 0,
  borderBottom: isNested ? 'none' : undefined,
  verticalAlign: 'top',
});

const DataRow = ({ itemKey, isNested = false, children, icon, highlighted, isMobile, isObject }: DataRowProps) => {
  if (isMobile) {
    return <DataRowMobile itemKey={itemKey} isNested={isNested} children={children} icon={icon} highlighted={highlighted} isObject={isObject} />;
  } else {
    return <DataRowDesktop itemKey={itemKey} isNested={isNested} children={children} icon={icon} highlighted={highlighted} isObject={isObject}/>;
  }

};

const DataRowDesktop = ({ itemKey, isNested = false, children, icon, highlighted }: Omit<DataRowProps, 'isMobile'>) => (
  <TableRow
    key={itemKey}
    sx={{
      backgroundColor: highlighted ? '#ededed' : undefined,
    }}
  >
    <TableCell
      component="th"
      scope="row"
      sx={createLabelStyles(isNested)}
    >
      <ItemKey>
        {icon ? (
          <Chip
            sx={{ background: 'transparent' }}
            icon={icon}
            label={String(itemKey)}
            size="small"
          />
        ) : itemKey}
      </ItemKey>
    </TableCell>
    <TableCell sx={{ borderBottom: isNested ? 'none' : undefined }}>
      {children}
    </TableCell>
  </TableRow>
);

const DataRowMobile = ({ itemKey, isNested = false, children, icon, highlighted, isObject = false }: Omit<DataRowProps, 'isMobile'>) => (
  <Box
    key={itemKey}
    sx={{
      marginLeft: isNested ? '10px' : '0px',
      backgroundColor: highlighted ? '#ededed' : undefined,

    }}
  >
    <span>
      <ItemKeyMobile isObject={isObject}>
        {icon ? (
          <Chip
            sx={{ background: 'transparent', fontSize: '16px' }}
            icon={icon}
            label={String(itemKey)}
          />
        ) : itemKey}
      </ItemKeyMobile>
      <span style={{ marginLeft: '5px' }}>
        {!isObject && !highlighted && <span style={{ marginRight: '5px' }}>=</span>}
        {children}
      </span>
    </span>

  </Box>
);

export default DataRow;