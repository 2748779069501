import { Box } from '@mui/material';
import useDeviceType from '../../data/useDeviceType';

const PhyTabPanel = ({ value, index, children }: { value: number, index: number, children: React.ReactNode }) => {
  const { isDesktop } = useDeviceType();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={isDesktop ? 2 : 0.5}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default PhyTabPanel;