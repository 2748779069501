import { useContext, useEffect, useState } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import SeasonTrayAssign from './components/SeasonTrayAssign';
import { Box, Button } from '@mui/material';
import SeasonTrayUnassign from './components/SeasonTrayUnassign';
import useSeason from './hooks/useSeason';
import { AlertDialog } from '../../../components/AlertDialog/AlertDialog';

const SideBarSeasonTrayEditor = ({
  seasonId,
}: {
  seasonId: string;
  columnId: number;
  shelves: number;
  rows: number;
}) => {
  const { columnSpec, brokerId } = useContext(FarmContext);
  const [isUnassign, setIsUnassign] = useState<boolean>(false);
  const [isStopped, setIsStopped] = useState<boolean>(false);
  const seasonData = useSeason(seasonId);

  const title = isUnassign ? 'Unassign Trays' : 'Assign Trays';

  useEffect(() => {
    if (!seasonData) return;
    const _is = seasonData.state === 'seeded' ||
    seasonData.state === 'stopped' ||
    seasonData.state === 'paused' ||
    seasonData.state === 'completed';

    setIsStopped(_is);

  }, [seasonData]);
  return (
    <>
      <AppDrawerTitle title={title} fullscreenOnly />
      {isUnassign && (
        <Box sx={{ position: 'absolute', top: '-5px', left: 33, overflow: 'auto', zIndex: 100 }}>
          <Button variant="contained" color="info" sx={{ m: 1 }} onClick={() => setIsUnassign(false)}>Assign</Button>
        </Box>
      )}
      {!isUnassign && isStopped && (
        <Box sx={{ position: 'absolute', top: '-5px', left: 33, overflow: 'auto', zIndex: 100 }}>
          <Button variant="contained" color="info" sx={{ m: 1 }} onClick={() => setIsUnassign(true)}>Unassign Instead</Button>
        </Box>
      )}
      {!isStopped && (
        <Box sx={{ position: 'absolute', top: '-5px', left: 33, overflow: 'auto', zIndex: 100 }}>
          <AlertDialog
            message="Please pause/stop season first"
            trigger={(handleOpen) => (
              <Button
                variant="contained"
                color="info"
                sx={{ m: 1 }}
                onClick={handleOpen}
              >
                Unassign Instead
              </Button>
            )}
          />
        </Box>
      )}
      {columnSpec.map(({ shelves, rows }, key) => {
        return !isUnassign ? (
          <SeasonTrayAssign
            key={key}
            brokerId={brokerId}
            seasonId={seasonId}
            columnId={key + 1}
            shelves={shelves}
            rows={rows}
          />
        ) : (
          <SeasonTrayUnassign
            key={key}
            brokerId={brokerId}
            seasonId={seasonId}
            columnId={key + 1}
            shelves={shelves}
            rows={rows}
          />
        );
      })}
    </>
  );
};

export default SideBarSeasonTrayEditor;
