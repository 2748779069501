import { useState, useEffect } from 'react';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import type { DatabaseSeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';

const useSeason = (
  seasonId?: string,
): FromFirebase<DatabaseSeason> | undefined => {
  const [season, setSeason] = useState<FromFirebase<DatabaseSeason>>();

  useEffect(() => {
    if (!seasonId) {
      console.error('no season');
      return;
    }

    const db = getFirestore();
    const seasonDocRef = doc(db, 'season', seasonId);

    const unsubscribe = onSnapshot(
      seasonDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const seasonData = {
            ...docSnapshot.data(),
            id: docSnapshot.id,
          } as FromFirebase<DatabaseSeason>;

          setSeason(seasonData);
        }
      },
      (err) => {
        console.error(err);
      },
    );

    return () => {
      unsubscribe();
    };
  }, [seasonId]);
  return season;
};

export default useSeason;
