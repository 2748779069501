import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import React, { useContext } from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import ControlIrrigation from './ControlIrrigation';
import GraphIrrigation from './GraphIrrigation';
import useLatestIrrigation from './hooks/useLatestIrrigation';
import LatestIrrigation from './LatestIrrigation';
import FarmContext from '../../components/FarmProvider/FarmContext';
import LatestIrrigationInfo from './LatestIrrigationInfo';
import ConfigIrrigation from './ConfigIrrigation';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestIrrigationFaults from './LatestIrrigationFaults';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';

type IProps = {
  deviceId: string;
};

const SideBarEntryIrrigation: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const [value, setValue] = React.useState<any>(0);

  const latestDeviceInfo = useLatestIrrigation(brokerId, deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity="info">No Latest Data!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`irrigation/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Activity" value={4} />
        <Tab label="Alerts" value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestIrrigationInfo latest={latestDeviceInfo} />
        <LatestIrrigationFaults latest={latestDeviceInfo} />
        <LatestIrrigation latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphIrrigation latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlIrrigation latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigIrrigation latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/irrigation/${deviceId}/notes`}
          heading="Activity Log"
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType="irrigation"
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryIrrigation;
