/* eslint-disable @typescript-eslint/no-unused-vars */
import { isJson } from '@phyllome/common';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useUser } from 'reactfire';

type DeviceWebsocketRunProps = {
  brokerId: string;
};

//const SOCKETURL = 'ws://localhost:8090';
const SOCKETURL2 = 'wss://34.116.121.14:8090';
const DeviceWebsocketRun: FC<DeviceWebsocketRunProps> = () => {
  const [socketUrl, setSocketUrl] = useState('');
  const [token, setToken] = useState('');
  const user = useUser();

  useEffect(() => {
    async function fetchData() {
      if (user.data) {
        const idToken = await user.data.getIdToken();

        // Make sure we're using wss:// for production or if your server requires it
        setSocketUrl(SOCKETURL2);
        setToken(idToken);
      }
    }
    fetchData();
  }, [user]);

  if (!socketUrl || !token) return <div>Loading...</div>;
  return <DeviceWebsocket socketUrl={socketUrl} token={token}/>;
};

const DeviceWebsocket = ({ socketUrl, token }: { socketUrl: string, token: string }) => {
  const [connectionStatus, setConnectionStatus] = useState<string>('Connecting...');

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    protocols: token,
    onOpen: () => {
      console.log('WebSocket Connected');
      setConnectionStatus('Connected');

      // Send an initial message to test the connection
      sendMessage(JSON.stringify({
        type: 'hello',
        message: 'Connected to WebSocket',
      }));
    },
    onClose: (event) => {
      console.log('WebSocket Closed:', event);
      setConnectionStatus(`Closed: ${event.reason || 'No reason provided'}`);
    },
    onError: (event) => {
      console.error('WebSocket Error:', event);
      setConnectionStatus('Error occurred');
    },
    onMessage: (event) => {

      if (isJson(event.data)) {
        console.log('Received message:', JSON.parse(event.data));
      }
    },
    // Add reconnect options
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: 3000,
    reconnectAttempts: 10,

  });

  // Add a more detailed status display
  useEffect(() => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        setConnectionStatus('Connecting...');
        break;
      case ReadyState.OPEN:
        setConnectionStatus('Open');
        break;
      case ReadyState.CLOSING:
        setConnectionStatus('Closing...');
        break;
      case ReadyState.CLOSED:
        setConnectionStatus('Closed');
        break;
      default:
        setConnectionStatus('Unknown');
        break;
    }
  }, [readyState]);
  return (
    <div>
      <div>Connection Status: {connectionStatus}</div>
      <div>ReadyState: {ReadyState[readyState]}</div>

    </div>
  );
};

export default DeviceWebsocketRun;