import type { ReactNode } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// Components
const ItemKey = ({ children }: { children: ReactNode }) => {
  return (
    <div style={{ marginRight: '8px', textAlign: 'left' }}>
      {children}
    </div>
  );
};

export const ItemKeyMobile = ({ children, isObject }: { children: ReactNode, isObject: boolean }) => {
  return (
    <span style={{ marginRight: '0px', textAlign: 'left' }}>

      {children}
      {isObject && <div style={{ display: 'inline-block', position: 'relative', 'left': '4px', 'top': '6px' }}><ArrowDownwardIcon style={{ width: '18px' }}/></div>}
    </span>
  );
};

export default ItemKey;