import { Button, Box } from '@mui/material';
import { useMemo, useState } from 'react';
import type { Coordinate } from 'recharts/types/util/types';
import ScaffoldRack from '../../../../components/factoryui/ScaffoldRack/ScaffoldRack';
import OverlayTraySelect from '../../Trays/Overlays/OverlayTraySelect';
import OverlayTraySimple from '../../Trays/Overlays/OverlayTraySimple';
import ModalAddSeason from '../modals/ModalAddSeason';
import createHashedListFromTraySeason from '../../../../helpers/createHashedListFromTraySeason';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';

const SeasonTrayAssign = ({
  brokerId,
  seasonId,
  columnId,
  shelves,
  rows,
}: {
  brokerId: string;
  seasonId: string;
  columnId: number;
  shelves: number;
  rows: number;
}) => {
  const [refreshSeed, setRefreshSeed] = useState<number>(0);
  const traySeasons = useTraySeasonsCurrentByColID({ brokerId, columnId, refresh: refreshSeed });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Coordinate[]>([]);
  const [hideChildren, setHideChildren] = useState<boolean>(false);
  const [selectionDisabled, setSelectionDisabled] = useState<boolean>(false);

  const blackList = useMemo(() => {
    return createHashedListFromTraySeason(traySeasons);
  }, [traySeasons]);

  return (
    <>
      <ModalAddSeason
        seasonId={seasonId}
        onClose={() => {
          setOpenModal(false);
          setRefreshSeed(refreshSeed + 1);
        }}
        isOpened={openModal}
        trays={selected}
        columnId={columnId}
      />
      <Box sx={{ pt: 1 }}>
        <ScaffoldRack
          column={columnId}
          onMouseEnter={() => {
            setHideChildren(false);
          }}
          onMouseLeave={() => {
            setHideChildren(true);
          }}
        >
          <OverlayTraySimple
            data={traySeasons}
            shelves={shelves}
            selectedSeason={seasonId}
          />
          <OverlayTraySelect
            blackList={blackList}
            disabled={selectionDisabled}
            shelves={shelves}
            rows={rows}
            onSelect={(_selected) => {
              setSelected(_selected);
            }}
            hideChildren={hideChildren}
          >
            <Box sx={{ position: 'absolute', left: '0' }}>
              <Button
                color="secondary"
                variant="contained"
                sx={{ width: '160px' }}
                onMouseEnter={() => {
                  setSelectionDisabled(true);
                }}
                onMouseLeave={() => {
                  setSelectionDisabled(false);
                }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Add {selected.length} Trays
              </Button>
            </Box>
          </OverlayTraySelect>
        </ScaffoldRack>
      </Box>
    </>
  );
};

export default SeasonTrayAssign;
