import RackBox from '../../../../components/factoryui/components/RackBox';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import type { NDSDeviceFromAction } from '../../../../types/types';
import { colors } from '@phyllome/common';
import useNavSide from '../../../../data/hooks/useNavSide';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
};

const OverlaySprinklerValves = ({ shelves, data }: OverlayTraySimpleProps) => {
  const navSide = useNavSide();

  return (
    <>
      {data.map((row) => {
        const { valve, enable, location_row, location_shelf } = row.properties;
        const color = valve ? colors.river : enable ? colors.ocean : colors.fog;

        return (
          <RackBox
            style={{ textAlign: 'left', cursor: 'pointer' }}
            key={row.deviceId}
            zIndex={11}
            onClick={() => navSide(`sprinklers/${row.deviceId}`)}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
          >

            <WaterDropIcon
              sx={{ fontSize: '11px', margin: '1px', color: color }}
            />

          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySprinklerValves;
