import type { FC } from 'react';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import type { AirDeviceState } from '@phyllome/common';
import unflatten from '../../helpers/unflatten';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';

type IProps = {
  latest: FromFirebase<AirDeviceState>;
};

const LatestAir: FC<IProps> = ({ latest }) => {

  return (
    <PaperSection
      heading="Latest Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={unflatten(latest) as any} />

    </PaperSection>
  );
};

export default LatestAir;
