import type { AnyObject } from '@phyllome/common';
import type { DataValue } from '../types';

export const checkEmptyData = (data: unknown) => {
  if (!data && data !== false) {
    return '(empty)';
  }

  if (typeof data !== 'object') {
    return String(data);
  }

  if (Object.keys(data as object).length === 0) {
    return '(empty)';
  }
  return null;
};

export const formatAndOrderData = (data: AnyObject) => {
  const formattedData: DataValue[] = Object.entries(data).map(([key, value]) => ({
    key,
    value,
    type: getType(key, value),
  }));

  const priorityKeys = ['brokerId', 'timestamp', 'deviceId', 'alias', 'deviceType'];

  return formattedData.sort((a, b) => {
    const indexA = priorityKeys.indexOf(a.key);
    const indexB = priorityKeys.indexOf(b.key);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    const numA = parseInt(a.key.replace(/\D/g, ''));
    const numB = parseInt(b.key.replace(/\D/g, ''));

    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    return a.key.localeCompare(b.key);
  });
};

export const getType = (key: string, value: unknown): string => {
  if (key === 'brokerId') return 'brokerId';
  if (key === 'deviceId') return 'deviceId';
  if (key === 'deviceType') return 'deviceType';
  if (key === 'alias') return 'alias';

  if (typeof value === 'object' && value !== null && 'nanoseconds' in value) return 'timestamp';
  if (Array.isArray(value)) return 'array';
  return typeof value;
};