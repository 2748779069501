import type { DatabaseSeason } from '@phyllome/common';
import type { FC } from 'react';
import type { FromFirebase } from '../../../../types/types';
import useAction from '../../../../data/hooks/useAction';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ScheduleViewLighting from '../../../../components/ScheduleView/ScheduleViewLighting';
import useSeeds from '../../../../data/hooks/collections/useSeeds';
import { LightingScheduleEdit } from '../../../../components/ScheduleEdit/ScheduleEdit';
import ScheduleTableLighting from '../../../../components/ScheduleTable/ScheduleTableLighting';

type IProps = {
  seasonData: FromFirebase<DatabaseSeason>;
};

const SideBarSeasonLightingSchedule: FC<IProps> = ({ seasonData }) => {
  const updateSeason = useAction('seasonUpdate');

  const seedData = useSeeds(); // MICK THIS GRABS ALL THE SEEDS FYI
  const selectedSeedData = seedData.find(
    (seed) => seed.id === seasonData.seedId,
  );

  if (seasonData?.state === 'completed') {
    return    (
      <PaperSection
        heading="Lighting Schedule"
        mb={2}
      >
        <ScheduleTableLighting scheduleDataDB={seasonData?.defaultLightingSchedule || []} />
      </PaperSection>);
  } else {
    return (
      <PaperSection
        heading="Lighting Schedule"
        mb={2}
      >
        <ScheduleViewLighting
          seasonSchedule={seasonData?.defaultLightingSchedule}
          seedSchedule={selectedSeedData?.defaultLightingSchedule}
          context="season"
        />
        <LightingScheduleEdit
          isSaving={updateSeason.status === 'RUNNING'}
          data={seasonData?.defaultLightingSchedule}
          onSubmit={(data) => {
            updateSeason.run({
              brokerId: seasonData.brokerId,
              id: seasonData.id,
              defaultLightingSchedule: data as any,
            });
          }}
        />
      </PaperSection>
    );
  };
};

export default SideBarSeasonLightingSchedule;
