import { Timestamp } from 'firebase/firestore';

// Type predicate to check if something is a Timestamp
const isTimestamp = (value: unknown): value is Timestamp => {
  return value instanceof Timestamp;
};

// Type utility to convert Timestamp types to Date types
type TimestampToDate<T> = {
  [K in keyof T]: T[K] extends Timestamp | null | undefined
    ? Date | null | undefined
    : T[K] extends Timestamp | null
      ? Date | null
      : T[K] extends Timestamp
        ? Date
        : T[K] extends object
          ? TimestampToDate<T[K]>
          : T[K];
};

const objectAsDates = <T extends object>(obj: T): TimestampToDate<T> => {
  const result: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    const value = obj[key];

    if (value === null || value === undefined) {
      result[key] = value;
    } else if (isTimestamp(value)) {
      result[key] = value.toDate();
    } else if (typeof value === 'object') {
      result[key] = objectAsDates(value);
    } else {
      result[key] = value;
    }
  }
  return result as TimestampToDate<T>;
};

export default objectAsDates;