import { Button, Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import type { Coordinate } from 'recharts/types/util/types';
import ScaffoldRack from '../../../../components/factoryui/ScaffoldRack/ScaffoldRack';
import OverlayTraySelect from '../../Trays/Overlays/OverlayTraySelect';
import OverlayTraySimple from '../../Trays/Overlays/OverlayTraySimple';
import createHashedListFromTraySeason from '../../../../helpers/createHashedListFromTraySeason';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import ModalDeleteTrays from '../modals/ModalDeleteTrays';
import type { FromFirebase } from '../../../../types/types';
import type { DatabaseTraySeason } from '@phyllome/common';

const SeasonTrayUnassign = ({
  brokerId,
  seasonId,
  columnId,
  shelves,
  rows,
}: {
  brokerId: string;
  seasonId: string;
  columnId: number;
  shelves: number;
  rows: number;
}) => {
  const [refreshSeed, setRefreshSeed] = useState<number>(0);

  const traySeasons = useTraySeasonsCurrentByColID({ brokerId, columnId, refresh: refreshSeed });

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<Coordinate[]>([]);
  const [hideChildren, setHideChildren] = useState<boolean>(false);
  const [selectionDisabled, setSelectionDisabled] = useState<boolean>(false);
  const [trayObjectsSelected, setTrayObjectsSelected] = useState<FromFirebase<DatabaseTraySeason>[]>([]);

  const traySeasonsBySeason = useMemo(() => {
    return traySeasons.filter((tray) => tray.seasonId === seasonId);
  }, [traySeasons, seasonId]);

  const whiteList = useMemo(() => {
    return createHashedListFromTraySeason(traySeasonsBySeason);
  }, [traySeasonsBySeason]);

  useEffect(() => {

    const _selectedTrays = selected.map((tray) => {
      const _row = tray.x;
      const _shelf = tray.y;
      const _column = columnId;

      return traySeasonsBySeason.find(tr => {
        return tr.locationColumn === _column && tr.locationRow === _row && tr.locationShelf === _shelf;
      });

    }).filter(t => t !== undefined);

    setTrayObjectsSelected(_selectedTrays);

  }, [selected, traySeasonsBySeason, columnId]);
  return (
    <>

      <ModalDeleteTrays
        seasonId={seasonId}
        onClose={() => {
          setOpenModal(false);
          setRefreshSeed(refreshSeed + 1);
        }}
        isOpened={openModal}
        trays={trayObjectsSelected}
        columnId={columnId}
      />
      <Box sx={{ pt: 1 }}>
        <ScaffoldRack
          column={columnId}
          onMouseEnter={() => {
            setHideChildren(false);
          }}
          onMouseLeave={() => {
            setHideChildren(true);
          }}
        >
          <OverlayTraySimple
            data={traySeasonsBySeason}
            shelves={shelves}
            selectedSeason={seasonId}
          />
          <OverlayTraySelect
            whiteList={whiteList}
            disabled={selectionDisabled}
            shelves={shelves}
            rows={rows}
            onSelect={(_selected) => {
              setSelected(_selected);
            }}
            hideChildren={hideChildren}
          >
            <Box sx={{ position: 'absolute', left: '0' }}>
              <Button
                color="secondary"
                variant="contained"
                sx={{ width: '160px' }}
                onMouseEnter={() => {
                  setSelectionDisabled(true);
                }}
                onMouseLeave={() => {
                  setSelectionDisabled(false);
                }}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Remove these {selected.length} trays
              </Button>
            </Box>
          </OverlayTraySelect>
        </ScaffoldRack>
      </Box>
    </>
  );
};

export default SeasonTrayUnassign;
