import { Stack } from '@mui/system';
import { SelectElement, SliderElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import type { CameraDeviceState } from '@phyllome/common';
import { mqttDefinitionCameraConfigSet } from '@phyllome/common';
import useActivityLog from '../../../data/hooks/useActivityLog';
import type { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const mqttDefinitionCameraConfigSetExtended = {
  ... mqttDefinitionCameraConfigSet,
  payload: {
    ...mqttDefinitionCameraConfigSet.payload,
    visible: {
      ...mqttDefinitionCameraConfigSet.payload.visible,
      gain_blue: 0,
      gain_red: 0,
    },
  },
};

const CameraResolutionOptions = [
  { id: '4056,3040', label: '4056x3040 (12MP)' },
  { id: '3280,2464', label: '3280x2464 (8MP)' },
  { id: '2560,1920', label: '2560x1920 (5MP)' },
  { id: '2048,1536', label: '2048x1536 (3MP default)' },
  { id: '1920,1080', label: '1920x1080 (2MP)' },
  { id: '1640,1232', label: '1640x1232 (2MP wide)' },
  { id: '1280,720', label: '1280x720 (1MP)' },
];

const processGainSliderValue = (value:any) => {
  if (value === 'auto') {
    return 0;
  }
  return value || 0;
};

const formatAWBGains = (red:number, blue:number): string => {
  const val = `${red || 0},${blue || 0}`;

  return (val === '0,0') ? 'auto' : val;
};

const FormCameraConfigCameraSettingsVisible = ({
  latest,
}: {
  latest: FromFirebase<CameraDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCameraConfigSet, deviceId);
  const activityLog = useActivityLog(`device/camera/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCameraConfigSetExtended, {
    visible: {
      flash_mode: latest?.visible_flash_mode || 'auto',
      iso: latest?.visible_iso || 100,
      shutter_speed: latest?.visible_shutter_speed || 'auto',
      exposure_mode: latest?.visible_exposure_mode || 'auto',
      awb_gains: latest?.visible_awb_gains || 'auto',
      awb_mode: latest?.visible_awb_mode || 'auto',
      resolution: latest?.visible_resolution || '2048,1536',
      gain_red: processGainSliderValue(latest?.visible_awb_gains?.split(',')[0]),
      gain_blue: processGainSliderValue(latest?.visible_awb_gains?.split(',')[1]),
    },
  });

  const formRef = useRef<any>();
  const [awbGain, setAwbGain] = useState('auto');

  useEffect(() => {
    const context = formRef?.current.context;

    if (!context) return;

    context.watch((formData:any) => {
      setAwbGain(formatAWBGains(formData.gain_red, formData.gain_blue));
    });
  }, [formRef?.current?.context, defaultValues]);

  useEffect(() => {
    if (formRef?.current) {
      formRef.current.context.setValue('visible.awb_gains', awbGain);
    }
  }
  , [awbGain]);
  return (
    <PaperSection
      heading="Visible Camera Settings"
      mb={2}
      subheading={mqttDefinitionCameraConfigSet.topic}
    >
      <PhormContainer
        ref={formRef}
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              visible: {
                flash_mode: data?.visible?.flash_mode,
                iso: data?.visible?.iso,
                shutter_speed: data?.visible?.shutter_speed,
                exposure_mode: data?.visible?.exposure_mode,
                awb_gains: data?.visible?.awb_gains,
                awb_mode: data?.visible?.awb_mode,
                resolution: data?.visible?.resolution,
              },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add('Updated visible camera params', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
        })}
      >
        <Stack spacing={2}>
          <SelectElement
            name="visible.resolution"
            label="Resolution"
            options={CameraResolutionOptions}
          />
          <SelectElement
            name="visible.flash_mode"
            label="Flash Mode"
            options={[
              { id: 'off', label: 'Off' },
              { id: 'auto', label: 'Auto' },
              { id: 'on', label: 'On' },
              { id: 'redeye', label: 'Redeye' },
              { id: 'fillin', label: 'Fillin' },
              { id: 'torch', label: 'Torch' },
            ]}
          />
          <Box pl={2} pr={2}>
            <SliderElement
              label="ISO"
              marks
              max={800}
              min={100}
              step={100}
              name="visible.iso"
            />
          </Box>
          <TextFieldElement
            name="visible.shutter_speed"
            label="Shutter Speed"
            type="text"
          />
          <SelectElement
            name="visible.exposure_mode"
            label="Exposure Mode"
            options={[
              { id: 'off', label: 'Off' },
              { id: 'auto', label: 'Auto' },
              { id: 'night', label: 'Night' },
              { id: 'nightpreview', label: 'Night Preview' },
              { id: 'backlight', label: 'Backlight' },
              { id: 'spotlight', label: 'Spotlight' },
              { id: 'sports', label: 'Sports' },
              { id: 'snow', label: 'Snow' },
              { id: 'beach', label: 'Beach' },
              { id: 'verylong', label: 'Very Long' },
              { id: 'fixedfps', label: 'Fixed FPS' },
              { id: 'antishake', label: 'Anti Shake' },
              { id: 'fireworks', label: 'Fireworks' },
            ]}
          />
          <SelectElement
            name="visible.awb_mode"
            label="AWB Mode"
            options={[
              { id: 'off', label: 'Off' },
              { id: 'auto', label: 'Auto' },
              { id: 'sunlight', label: 'Sunlight' },
              { id: 'cloudy', label: 'Cloudy' },
              { id: 'shade', label: 'Shade' },
              { id: 'tungsten', label: 'Tungsten' },
              { id: 'fluorescent', label: 'Fluorescent' },
              { id: 'incandescent', label: 'Incandescent' },
              { id: 'flash', label: 'Flash' },
              { id: 'horizon', label: 'Horizon' },
            ]}
          />
          <TextFieldElement
            name="visible.awb_gains"
            label="AWB Gains"
            inputProps={{ readOnly: true }}
          />
          <Box pl={2} pr={2}>
            <SliderElement
              label="AWB gain - RED"
              marks
              max={3}
              min={0}
              step={.1}
              name="gain_red"
            />
          </Box>
          <Box pl={2} pr={2}>
            <SliderElement
              label="AWB gain - BLUE"
              marks
              max={3}
              min={0}
              step={.1}
              name="gain_blue"
            />
          </Box>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCameraConfigCameraSettingsVisible;
