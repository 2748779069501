import { useEffect, useState } from 'react';
import type { DatabaseTraySeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import {
  Box,
  IconButton,
  Button,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from '@mui/material';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import TrayImage from '../../../../components/TrayImage/TrayImage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { OpenInNew } from '@mui/icons-material';
import type { DatabaseTraySeasonPhotos } from '@phyllome/common';
import { howLongAgoInEnglish } from '../../../../helpers/FStimeAgo';
import dateToString, {
  dateFormatTypes,
} from '../../../../helpers/dateToString';
import useTrayPhoto from '../Hooks/useTrayPhoto';

type PhotoType = DatabaseTraySeasonPhotos['type'];

const SideBarTraySeasonPhoto = ({
  traySeasonData,
}: {
  traySeasonData: FromFirebase<DatabaseTraySeason>;
}) => {
  const allTrayPhotos = useTrayPhoto({ traySeasonId: traySeasonData.id });
  const [trayPhotoType, setTrayPhotoType] = useState<PhotoType>('visible');
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);

  const trayPhotos = allTrayPhotos.filter(item => item.type === trayPhotoType);

  // Initialize to most recent photo or handle photo type changes
  useEffect(() => {
    if (trayPhotos.length > 0 && (currentIndex === undefined || currentIndex >= trayPhotos.length)) {
      setCurrentIndex(trayPhotos.length - 1);
    }
  }, [trayPhotos.length, currentIndex, trayPhotoType]);

  const handleTrayPhotoToggle = (
    event: React.MouseEvent<HTMLElement>,
    newTrayPhotoType: PhotoType | null,
  ) => {
    if (newTrayPhotoType) {
      setTrayPhotoType(newTrayPhotoType);
    }
  };

  // Return null if we have no photos or haven't initialized the index
  if (trayPhotos.length === 0 || currentIndex === undefined || currentIndex < 0) {
    return null;
  }

  const currentPhoto = trayPhotos[currentIndex];
  const canGoPrevious = currentIndex > 0;
  const canGoNext = currentIndex < trayPhotos.length - 1;

  const handlePrevious = () => {
    if (canGoPrevious) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (canGoNext) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <PaperSection
      mb={2}
      heading="Latest Image"
      subheading={`Taken ${dateToString(
        currentPhoto.timestamp?.toDate(),
        dateFormatTypes.TRUNCATEDNOSECONDS2,
      )} (${howLongAgoInEnglish(currentPhoto.timestamp)} ago)`}
    >
      <Stack
        direction="row"
        sx={{ width: '100%' }}
        justifyContent="space-between"
        mb={2}
      >
        <Box>
          <Button
            variant="contained"
            disabled={!canGoPrevious}
            onClick={handlePrevious}
          >
            <ArrowBackIcon />
          </Button>
        </Box>
        <Box sx={{ lineHeight: '36px' }}>
          {currentIndex + 1} of {trayPhotos.length}
        </Box>
        <Box>
          <Button
            variant="contained"
            disabled={!canGoNext}
            onClick={handleNext}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
      </Stack>
      <TransformWrapper>
        <TransformComponent>
          <TrayImage src={currentPhoto.url} />
        </TransformComponent>
      </TransformWrapper>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            marginTop: '-37px',
            zIndex: 101,
          }}
        >
          <a
            href={currentPhoto.url}
            download="download"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="fog">
              <OpenInNew />
            </IconButton>
          </a>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          textAlign: 'center',
          marginTop: '-45px',
          zIndex: 100,
        }}
      >
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={trayPhotoType}
          exclusive
          onChange={handleTrayPhotoToggle}
          aria-label="TrayPhotoType"
          sx={{ background: 'white' }}
        >
          <ToggleButton value="ir">Infrared</ToggleButton>
          <ToggleButton value="visible">Visible</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="caption" color="textSecondary">ISO: {currentPhoto?.camera?.iso}
          - Shutter: {currentPhoto?.camera?.shutter_speed}
          - AWB gains: {currentPhoto?.camera?.awb_gains}
        </Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }} />
    </PaperSection>
  );
};

export default SideBarTraySeasonPhoto;