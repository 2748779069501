import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import type { TransferDeviceState } from '@phyllome/common';
import unflatten from '../../helpers/unflatten';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';

const LatestTransfer = ({
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {

  return (
    <PaperSection
      heading="Latest Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestTransfer;
