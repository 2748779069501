import ModalCustom from '../../../../components/ModalCustom/ModalCustom';
import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useAction from '../../../../data/hooks/useAction';
import type { DatabaseTraySeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';
import { Box, Chip } from '@mui/material';
import SafeDelete from '../../../../components/SafeDelete/SafeDelete';

type ModalAddSeasonProps = {
  trays: FromFirebase<DatabaseTraySeason>[];
  columnId: number;
  isOpened: boolean;
  seasonId: string;
  onClose: () => void;
};

const ModalDeleteTrays = ({
  isOpened,
  trays,
  columnId,
  seasonId,
  onClose,
}: ModalAddSeasonProps) => {
  const farmData = useContext(FarmContext);

  const traySeasonDeleteTrays = useAction('traySeasonDeleteTrays');

  if (trays.length === 0) {
    return null;
  }
  return (
    <ModalCustom
      title={`Delete ${trays.length} Trays in Column ${columnId}`}
      isOpened={isOpened}
      onClose={onClose}
    >
      <Box>You are about to delete these trays, <strong>this can't be undone.</strong></Box>
      <Box sx={{ mb: 2, mt: 2 }}>
        {trays.map((tray) => {
          const label = `${tray.locationColumn}/${tray.locationShelf}/${tray.locationRow}`;

          return (
            <><Chip sx={{ marginRight: '10px' }} label={label}  /></>
          );

        })}
      </Box>
      <SafeDelete
        deleteButtonText="Remove Trays"  onComplete={function ()  {
          traySeasonDeleteTrays.run({
            brokerId: farmData.brokerId,
            seasonId: seasonId,
            ids: trays.map((tray) => tray.id),
          }).then(() => {
            onClose();
          });
        }}
      />
    </ModalCustom>
  );
};

export default ModalDeleteTrays;
