import type { PackagerDeviceState } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import ObjectToTable from '../../components/ObjectToTable/ObjectToTable';
import unflatten from '../../helpers/unflatten';

const LatestPackager = ({
  latest,
}: {
  latest: FromFirebase<PackagerDeviceState>;
}) => {
  return (
    <PaperSection
      heading="Latest Packager Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ObjectToTable data={unflatten(latest) as any} />
    </PaperSection>
  );
};

export default LatestPackager;
