import { Alert, Box } from '@mui/material';
import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import useSeason from './hooks/useSeason';
import { useContext } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import SideBarSeasonNDSSchedule from './components/SideBarSeasonNDSSchedule';
import SideBarSeasonConsumables from './components/SideBarSeasonConsumables';
import SideBarSeasonProfit from './components/SideBarSeasonProfit';
import SideBarSeasonInfo from './components/SideBarSeasonInfo';
import SideBarSeasonEditorTop from './SideBarSeasonEditorTop';
import SideBarSeasonDevices from './components/SideBarSeasonDevices';
import SideBarSeasonTasksProgress from './components/SideBarSeasonTasksProgress';
import SideBarSeasonEditGrowthTimeline from './components/SideBarSeasonEditGrowthTimeline';
import SideBarSeasonHardwareChanges from './components/SideBarSeasonHardwareChanges';
import SideBarSeasonCompletedSeasonEdit from './components/SideBarSeasonCompletedSeasonEdit';
import SideBarSeasonEditSeasonDetails from './components/SideBarSeasonEditSeasonDetails';
import ActivityLog from '../../../components/ActivityLog/ActivityLog';
import SideBarSeasonLightingSchedule from './components/SideBarSeasonLightingSchedule';
import SideBarSeasonDangerZone from './components/SideBarSeasonDangerZone';

const SideBarSeasonEditor = ({ seasonId }: { seasonId: string }) => {
  const seasonData = useSeason(seasonId);
  const { brokerId } = useContext(FarmContext);

  if (!seasonData) return (<Alert severity="error">Season not found</Alert>);
  return (
    <>
      <AppDrawerTitle title={seasonData.name} />
      <SideBarSeasonEditorTop seasonData={seasonData} />
      <Box p={1}>
        <SideBarSeasonTasksProgress
          seasonData={seasonData}
          brokerId={brokerId}
        />
        <SideBarSeasonInfo
          seasonData={seasonData}
          brokerId={brokerId}
          seasonId={seasonId}
        />
        <SideBarSeasonProfit seasonData={seasonData} />
        <SideBarSeasonDevices seasonData={seasonData} />
        <SideBarSeasonConsumables seasonData={seasonData} />
        {/* <SideBarSeasonTimeline seasonData={seasonData} /> */}
        {/* <SideBarSeasonActions seasonData={seasonData} /> */}
        <SideBarSeasonNDSSchedule seasonData={seasonData} />
        <SideBarSeasonLightingSchedule seasonData={seasonData} />
        <ActivityLog
          identifier={`season/${seasonData.id}/notes`}
          heading="Activity Log"
        />
        <SideBarSeasonEditSeasonDetails seasonData={seasonData} />
        <SideBarSeasonEditGrowthTimeline seasonData={seasonData} />
        <SideBarSeasonHardwareChanges seasonData={seasonData} />
        <SideBarSeasonCompletedSeasonEdit seasonData={seasonData} />
        <SideBarSeasonDangerZone seasonData={seasonData} />
      </Box>
    </>
  );
};

export default SideBarSeasonEditor;
