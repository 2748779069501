import { Tabs, Tab } from '@mui/material';
import { type NDSDeviceState, colors } from '@phyllome/common';
import React, { useContext } from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import ControlNDS from './ControlNDS';
import ConfigNDS from './ConfigNDS';
import LatestNDS from './LatestNDS';
import FarmContext from '../../components/FarmProvider/FarmContext';
import LatestNDSInfo from './LatestNDSInfo';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import useDeviceByIdPoller from './hooks/useDeviceByIdPoller';
import GraphNDS from './GraphNDS';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';

type IProps = {
  locationString: string;
  brokerId: string;
};

const SideBarEntryNDS: FC<IProps> = ({ locationString }) => {
  const { brokerId } = useContext(FarmContext);

  const [value, setValue] = React.useState<number>(0);

  const ndsDevicePoll = useDeviceByIdPoller<NDSDeviceState>({
    deviceType: 'nds',
    deviceId: locationString,
  });

  if (!ndsDevicePoll) return null;
  const ndsDeviceState = ndsDevicePoll?.msg;

  if (!ndsDeviceState) return null;

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <>
      <AppDrawerTitle title={`nds/${ndsDeviceState.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Activity" value={4} />
        <Tab label="Alerts" value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestNDSInfo latest={ndsDeviceState} />
        <LatestNDS latest={ndsDeviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphNDS deviceState={ndsDeviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlNDS deviceState={ndsDeviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigNDS deviceState={ndsDeviceState} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/nds/${locationString}/notes`}
          heading="Activity Log"
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={ndsDeviceState.deviceId}
          deviceType="nds"
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryNDS;
