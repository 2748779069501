import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import React, { useContext } from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useLatestTraybot from './hooks/useLatestTraybot';
import LatestTraybot from './LatestTraybot';
import LatestTraybotInfo from './LatestTraybotInfo';
import GraphTraybot from './GraphTraybot';
import ControlTraybot from './ControlTraybot';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ConfigTraybot from './ConfigTraybot';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import PhyTabPanel from '../../components/PhyTabPanel/PhyTabPanel';

type IProps = {
  deviceId: string;
};

const SideBarEntryTraybot: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);

  const [value, setValue] = React.useState<any>(0);
  const latestDeviceInfo = useLatestTraybot(brokerId, deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity="info">No Latest Data for traybot/{deviceId}!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle
        title={`traybot/${latestDeviceInfo.deviceId} (column: ${latestDeviceInfo.column})`}
      />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Activity" value={4} />
        <Tab label="Alerts" value={5} />
      </Tabs>
      <PhyTabPanel value={value} index={0}>
        <LatestTraybotInfo latest={latestDeviceInfo} />
        <LatestTraybot latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={1}>
        <GraphTraybot latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={2}>
        <ControlTraybot latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={3}>
        <ConfigTraybot latest={latestDeviceInfo} />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/traybot/${deviceId}/notes`}
          heading="Activity Log"
        />
      </PhyTabPanel>
      <PhyTabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType="traybot"
        />
      </PhyTabPanel>
    </>
  );
};

export default SideBarEntryTraybot;
